import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import './fonts/Ubuntu-Light.ttf'; 
import './fonts/Ubuntu-Regular.ttf'; 
import './fonts/Ubuntu-Medium.ttf'; 


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

